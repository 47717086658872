import { render, staticRenderFns } from "./ppc-corrected-ppc-sales-report.vue?vue&type=template&id=21419ffe&scoped=true&"
import script from "./ppc-corrected-ppc-sales-report.ts?vue&type=script&lang=ts&"
export * from "./ppc-corrected-ppc-sales-report.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21419ffe",
  null
  
)

export default component.exports